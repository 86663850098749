$sigFont: effra, sans-serif;
$signatureFont: $sigFont;
$sigFontWide: effra, sans-serif;
$deco: effra, sans-serif;
$white: #fff;
$black: #000;
$red: #aa0d00;
// $red: #0C273A;
$pink:  $red;
$darkRed: #6f1624;
$orange: #f1751b;
$darkOrange: #984911;
// $background-color: #fffaec;
$background-color: #fff;
$purpleRadio: radial-gradient(circle, rgba(106,0,161,0.8925945378151261) 25%, rgba(156,4,95,0.7301295518207283) 100%);

$max-site-width: 840px;
